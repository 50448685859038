














































import { Component, Ref, Vue } from 'vue-property-decorator'
import ProjectCard from '@/components/projects/projects-list/components/ProjectCard.vue'
import WarningModal from '@/components/ui/WarningModal.vue'
import ConfirmationModal from '@/components/ui/ConfirmationModal.vue'
import SearchInput from '@/components/ui/SearchInput.vue'
import { userMapper } from '@/store/modules/user'
import PaginationMixin from '@/mixins/paginationMixin'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import ProjectsAPI from '../common/helpers/requests'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'

const Mappers = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isAuthenticated']),
    ...mobileWidthMapper.mapGetters(['isMobile'])
  },
  mixins: [PaginationMixin]
})

@Component({
  components: { SearchInput, ProjectCard, WarningModal, ConfirmationModal }
})
export default class ProjectsList extends Mappers {
  @Ref() searchInput!: SearchInput
  @Ref() authRequired!: WarningModal

  private canAddProject = false

  private projectsList: any[] = []

  private isShowMore!: false
  private fetchNext!: Function
  private resetPagination!: Function
  private busy = false
  private modelFilter: any = {}

  private addProject() {
    this.$router.push('/projects/add')
  }

  private onInputChanged(e: string) {
    this.modelFilter.title = {
      filterType: 'text',
      type: 'contains',
      filter: e
    }

    this.searchProjects()
  }

  private async fetchFunction(limit: number, offset: number, args: any) {
    return await ProjectsAPI.getProjects(limit, offset, null, args)
  }

  private async searchProjects() {
    this.resetPagination()
    this.projectsList = await this.fetchNext(
      this.fetchFunction,
      this.modelFilter
    )
  }

  private async loadMore() {
    this.projectsList = await this.fetchNext(
      this.fetchFunction,
      this.modelFilter
    )
  }

  private async onScroll() {
    if (
      !this.busy &&
      this.isShowMore &&
      document.documentElement.scrollHeight <=
        window.innerHeight + window.scrollY + 20
    ) {
      this.busy = true
      await this.loadMore()
      this.busy = false
    }
  }

  private created() {
    this.loadMore()
    window.addEventListener('scroll', this.onScroll)
    const ability = PermissionManager.common()
    this.canAddProject = ability.can(permissions.addProject, undefined)
  }
}
