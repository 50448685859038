























import { Component, Vue, Prop } from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'

@Component
export default class WarningModal extends Vue {
  @Prop(String) private readonly id!: string
  @Prop(String) private readonly title!: string
  @Prop({ default: 'md', type: String }) private readonly size!: string

  private onOkCallback?: () => void
  private onCancelCallback?: () => void

  $refs!: {
    warningModal: BModal
  }

  onOk() {
    this.onOkCallback?.()
    this.$refs.warningModal.hide()
  }

  onCancel() {
    this.onCancelCallback?.()
    this.$refs.warningModal.hide()
  }

  public show(onOkCallback?: () => void, onCancelCallback?: () => void) {
    this.onOkCallback = onOkCallback
    this.onCancelCallback = onCancelCallback
    this.$refs.warningModal.show()
  }
}
