




import { Component, Vue } from 'vue-property-decorator'
import ProjectsList from '@/components/projects/projects-list/index.vue'

@Component({ components: { ProjectsList } })
export default class ProjectsListRoute extends Vue {}
