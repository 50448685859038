











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SearchInput extends Vue {
  @Prop({ type: String, default: 'Поиск' }) readonly placeholder!: string

  private model = ''

  private onInput() {
    this.$emit('search', this.model)
  }

  public clear() {
    this.model = ''
  }
}
