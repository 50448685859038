


















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ConfirmationModal extends Vue {
  @Prop({ type: String, default: 'modalId' }) readonly id!: string

  @Prop({ type: String, default: 'Информация' }) readonly title!: string
  @Prop({ type: String, default: 'md' }) readonly size!: string
  @Prop({ type: String, default: 'Ок' }) readonly okText!: string
  @Prop({ type: String, default: 'Отмена' }) readonly cancelText!: string

  private onOk() {
    this.$emit('ok')
  }

  private onHidden() {
    this.$emit('hidden')
  }
}
